"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var scrollTo = function scrollTo(hash) {
  location.hash = hash;
};

function init() {
  var menuItems = document.getElementsByClassName("header__menu__item");
  var contactButton = document.getElementById("contact-button");
  var some = true;

  var _iterator = _createForOfIteratorHelper(menuItems),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var link = _step.value;
      link.addEventListener("click", function (event) {
        event.stopPropagation();
        event.preventDefault();
        console.log(event.target.getAttribute("href"));

        var _iterator2 = _createForOfIteratorHelper(menuItems),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            item.classList.remove("header__menu__item--active");
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        event.target.parentElement.classList.add("header__menu__item--active");
        scrollTo(event.target.getAttribute("href"));
      });
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  contactButton.addEventListener("click", scrollToBottom);
}

function replaceYear() {
  var yearContainer = document.getElementById("currentYear");
  var sdate = new Date();
  yearContainer.innerText = "- " + sdate.getFullYear();
}

function scrollToBottom() {
  console.log("scroll");
  window.scroll(0, document.body.scrollHeight || document.documentElement.scrollHeight);
}

init();
replaceYear();
"use strict";

function showMessage(message) {
  var processDiv = document.getElementById("process");
  processDiv.innerHTML = message;
  processDiv.style.display = "block";
}

function hideMessage() {
  var processDiv = document.getElementById("process");
  processDiv.style.display = "none";
}

function showForm() {
  var contactForm = document.getElementById("contactForm");
  contactForm.style.display = "block";
}

function hideForm() {
  var contactForm = document.getElementById("contactForm");
  contactForm.style.display = "none";
}

function sendAjaxForm(form, captchaToken) {
  var formData = new FormData(form);
  formData.append("captchaToken", captchaToken);
  var request = new XMLHttpRequest();
  request.addEventListener("load", function (response) {
    var status = response.target.status;

    if (status === 403) {
      showMessage("Captcha inválido intente nuevamente");
    } else {
      showMessage("Mensaje enviado, gracias!");
    }

    setTimeout(function () {
      showForm();
      hideMessage();
    }, 3000);
  });
  request.addEventListener("error", function () {
    showMessage("Ha ocurrido un error, intenta más tarde");
  });
  request.open("post", "send-email.php");
  request.send(formData);
}

function sendEmail(event) {
  event.preventDefault();
  showMessage("procesando solicitud");
  hideForm();
  grecaptcha.execute("6LeiBdQUAAAAAPIR-qp0YGPywdbZf-P9n_on92kS", {
    action: "homepage"
  }).then(function (token) {
    sendAjaxForm(contactForm, token);
  })["catch"](function (e) {
    console.error(e);
    hideMessage();
    showForm();
  });
}

var contactForm = document.getElementById("contactForm");
contactForm.addEventListener("submit", sendEmail);
